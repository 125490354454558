import { MouseEvent } from 'react';

import CpAvatar from '@/components/cp/cpAvatar/cpAvatar';
import {
    CpInfoType
} from '@/types/cpType';

import StreamingAvatarIcon from './img/streamingAvatar.svg';
import TickIcon from './img/tick.svg';

import { CreateType, InfoType } from '../cpMediaInfoCard';
import ChatIcon from '../img/chat.svg';
import FavoriteIcon from '../img/favorite.svg';
import ShareIcon from '../img/share.svg';
import SpeakerMutedIcon from '../img/speakerMuted.svg';
import SpeakerUnMutedIcon from '../img/speakerUnMuted.svg';
import SubscribeIcon from '../img/subscribe.svg';
import UnfavoriteIcon from '../img/unFavorite.svg';

import style from './videoSideMenu.module.scss';

type Props = {
    userInfo: CpInfoType;
    hideView: boolean;
    handleLikeClick: (event: React.MouseEvent<HTMLElement>) => void;
    isLike: boolean;
    handleChat: (event: React.MouseEvent<HTMLElement>, type?: string) => void;
    handleShareBtn: (event: MouseEvent<HTMLElement>, shareVideoUrl?: boolean) => void;
    likeCount: number;
    toggleSound: (event: React.MouseEvent<HTMLElement>) => void;
    isFollow: boolean;
    followingHandler: (event: React.MouseEvent<HTMLElement>) => void;
    handleToCpPage: () => void;
    isStreaming?: boolean;
    showSuccessTick: boolean;
    mediaData: InfoType | CreateType;
};

function VideoSideMenu({
    userInfo,
    hideView,
    handleLikeClick,
    isLike,
    handleChat,
    handleShareBtn,
    likeCount,
    toggleSound,
    isFollow = true,
    followingHandler,
    handleToCpPage,
    isStreaming = false, // TODO
    showSuccessTick,
    mediaData
}: Props) {
    return (
        !hideView && (
            <div className={style.videoSideMenu}>
                <div className={style.avatarBtn}>
                    {isStreaming && <StreamingAvatarIcon className={style.streaming} />}
                    <CpAvatar
                        className={style.avatar}
                        src={userInfo.avatar}
                        tag={userInfo.label}
                        width={36}
                        height={36}
                        onClick={handleToCpPage}
                    />
                    {showSuccessTick && <TickIcon className={style.subscribeIcon} />}
                    {!isFollow && (
                        <SubscribeIcon className={style.subscribeIcon} onClick={followingHandler} />
                    )}
                </div>
                <div className={style.like}>
                    <div className={style.icon} onClick={handleLikeClick}>
                        {isLike ? <FavoriteIcon /> : <UnfavoriteIcon />}
                    </div>
                    <div className={style.likeCount}>{likeCount}</div>
                </div>
                <div className={style.icon} onClick={event => handleChat(event)}>
                    <ChatIcon />
                </div>
                <div className={style.icon} onClick={event => handleShareBtn(event, true)}>
                    <ShareIcon />
                </div>
                <div className={style.icon} onClick={e => toggleSound(e)}>
                    {mediaData.isMuted ? <SpeakerMutedIcon /> : <SpeakerUnMutedIcon />}
                </div>
            </div>
        )
    );
}

export default VideoSideMenu;
