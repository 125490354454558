import { initStore } from '@/lib/store';
import type { StoreWithSelectors } from '@/lib/store';

interface baseState {
    hasVisited: boolean;
    setHasVisited: () => void;
    lastVisitTime: number;
    muted: boolean;
    setMuted: () => void;
}

let isInit = true;
let useVideoFeedStore: StoreWithSelectors<baseState>;

const initialState = (set: (updater: (state: baseState) => Partial<baseState>) => void) => ({
    hasVisited: false,
    setHasVisited: () => {
        set(() => ({
            hasVisited: true,
            lastVisitTime: new Date().getTime()
        }));
    },
    lastVisitTime: new Date().getTime(),
    muted: true,
    setMuted: () => {
        set((state) => ({
            muted: !state.muted
        }));
    },
});

const createVideoFeedStore = () => {
    if (isInit) {
        useVideoFeedStore = initStore<baseState>(initialState);
        isInit = false;
    }
};

export { createVideoFeedStore, useVideoFeedStore };
